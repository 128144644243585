import React from "react"
import SEO from "../components/SEO"

import styles from "../styles/modules/static-pages.module.scss"

const TermsOfService = () => (
  <>
    <SEO
      title="NeighborShare Terms of Service"
      description="Different stories, shared community"
    />
    <div className="wrapper wrapper--left">
      <h2 className={`x-large-body-copy ${styles.title}`}>Terms of service</h2>
      <section className={styles.legalSection}>
        <p className={`legalese-body-copy ${styles.legalParagraph}`}>
          Please read these Terms of Service (the “
          <span className={`underline`}>Agreement</span>”) carefully. Your use
          of the Site (as defined below) constitutes your consent to this
          Agreement.
        </p>
        <p className={`legalese-body-copy ${styles.legalParagraph}`}>
          This Agreement is between you and NeighborShare, Inc. (“
          <span className={`underline`}>NS</span>,” “
          <span className={`underline`}>we</span>” or “
          <span className={`underline`}>us</span>”) concerning your use of
          (including any access to) the website currently located at{" "}
          <a href="https://nbshare.org/">https://nbshare.org/</a> and any other
          sites operated by us from which you are accessing this Agreement
          (together with any materials and services available therein, and
          successor site(s) thereto, the “
          <span className={`underline`}>Site</span>”). This Agreement hereby
          incorporates by this reference any additional terms and conditions
          posted by NS through the Site, or otherwise made available to you by
          NS.
        </p>
        <p className={`legalese-body-copy ${styles.legalParagraph}`}>
          The Site provides a platform designed to help nonprofit organizations
          (“
          <span className={`underline`}>Partner Nonprofits</span>”) introduce
          households or individuals in need (“
          <span className={`underline`}>Recipients</span>”) to Site users who
          wish to make donations (each, a “
          <span className={`underline`}>Donation</span>”) (such persons, “
          <span className={`underline`}>Donors</span>”). By clicking on a link
          on the Site, Donors will be directed to a third-party payment platform
          that will direct Donations to an account held by the Partner
          Nonprofit. The Site is intended for use only by residents of the
          United States.
        </p>
        <p className={`legalese-body-copy ${styles.legalParagraph}`}>
          BY USING THE SITE, YOU AFFIRM THAT YOU ARE 18 YEARS OF AGE OR OLDER,
          HAVE THE RIGHT, AUTHORITY AND CAPACITY TO ENTER INTO AND ABIDE BY THIS
          AGREEMENT, AND HAVE READ, UNDERSTAND AND AGREE TO BE BOUND BY THIS
          AGREEMENT.
        </p>
        <p className={`legalese-body-copy ${styles.legalParagraph}`}>
          THIS AGREEMENT CONTAINS A MANDATORY INDIVIDUAL ARBITRATION AND CLASS
          ACTION/JURY TRIAL WAIVER PROVISION. THIS PROVISION REQUIRES THE USE OF
          ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN
          JURY TRIAL OR CLASS ACTIONS.
        </p>
        <p className={`legalese-body-copy ${styles.legalParagraph}`}>
          IF YOU ARE AN INDIVIDUAL ACCESSING OR USING THE SITE ON BEHALF OF, OR
          FOR THE BENEFIT OF, ANY CORPORATION, PARTNERSHIP OR OTHER ENTITY WITH
          WHICH YOU ARE ASSOCIATED (AN “
          <span className={`underline`}>ORGANIZATION</span>”), THEN YOU ARE
          AGREEING TO THIS AGREEMENT ON BEHALF OF YOURSELF AND SUCH
          ORGANIZATION, AND YOU REPRESENT AND WARRANT THAT YOU HAVE THE LEGAL
          AUTHORITY TO BIND SUCH ORGANIZATION TO THIS AGREEMENT. References to
          “you” and “your” in this Agreement will refer to both the individual
          using the Site and to any such Organization.
        </p>
      </section>
      <section className={styles.legalSection}>
        <h3>1. Partner Nonprofits.</h3>
        <p className={`legalese-body-copy ${styles.legalParagraph}`}>
          Partner Nonprofits provide Recipient profiles to be featured on the
          Site, make their own determinations with respect to how Donations are
          distributed, and are responsible for validating Recipient needs within
          the communities they serve.
        </p>
        <p className={`legalese-body-copy ${styles.legalParagraph}`}>
          Partner Nonprofits (and not NS) are responsible for distributing
          Donations to Recipients. Donations facilitated through the Site are to
          be directed via a third-party payment service to the Partner
          Nonprofit, and NS does not receive any Donations or funds from Donors.
        </p>
        <p className={`legalese-body-copy ${styles.legalParagraph}`}>
          No agency, partnership, joint venture, employer-employee or
          franchisor-franchisee relationship is intended or created by this
          Agreement. Partner Nonprofits do not have authority to enter into
          written or oral — whether implied or express — contracts on behalf of
          NS.
        </p>
      </section>
      <section className={styles.legalSection}>
        <h3>2. Donations; Reassignment of Certain Donations.</h3>
        <p className={`legalese-body-copy ${styles.legalParagraph}`}>
          Donors who wish to make Donations will be asked to provide certain
          relevant information, such as their name, country or region and postal
          code and payment card number and its expiration date and security
          code.
        </p>
        <p className={`legalese-body-copy ${styles.legalParagraph}`}>
          By providing such information to make a Donation, Donors:
          <ul className={styles.legalList}>
            <li>
              Represent and warrant that:
              <ul>
                <li>
                  They reside in the United States and have the right to use any
                  payment card submitted in connection with a Donation.
                </li>
                <li>
                  Donations are not being made to confer a personal or more than
                  incidental benefit on the Donor, any member of the Donor’s
                  family, or any entity affiliated with or controlled by the
                  Donor. No Donation will be made to satisfy a legally binding
                  charitable pledge, to permit participation in an event (such
                  as a dinner, raffle, or auction), or to pay for goods or
                  services received by Donor, Donor’s family member(s) or any
                  other person.
                </li>
              </ul>
            </li>
            <li>
              Grant to us the right to provide such information to third parties
              for purposes of facilitating Donations.
            </li>
            <li>
              Acknowledge and agree that:
              <ul>
                <li>
                  Donations will be provided to the Partner Nonprofit
                  corresponding to the Recipient of their choosing via a third
                  party payment service, such as Stripe.
                </li>
                <li>
                  Donor’s payment card will be charged in the amount of a
                  Donation. Verification of information may be required prior to
                  the acknowledgment or completion of any Donation. The third
                  party payment service will be responsible for providing Donors
                  with a date-stamped receipt of donation.
                </li>
                <li>
                  The Partner Nonprofit retains exclusive legal control over
                  Donations. While the Partner Nonprofit aims to grant Donations
                  to the Recipient selected by the Donor, in certain cases, the
                  Partner Nonprofit may choose to direct the Donation to a
                  similar household at its discretion (for example, if the needs
                  of the particular Recipient selected by the Donor are already
                  filled).
                </li>
              </ul>
            </li>
          </ul>
        </p>
        <p className={`legalese-body-copy ${styles.legalParagraph}`}>
          All Donations are made at Donors’ own risk. Donors may obtain
          information about Partner Nonprofits by consulting their Site profiles
          and/or the Partner Nonprofits’ websites.
        </p>
        <p className={`legalese-body-copy ${styles.legalParagraph}`}>
          NS reserves the right, including without prior notice, to bar any user
          from making any Donation.
        </p>
      </section>

      <section className={styles.legalSection}>
        <h3>3. NeighborShare Disclaimers.</h3>
        <p className={`legalese-body-copy ${styles.legalParagraph}`}>
          The Site is an administrative platform only. YOU HEREBY ACKNOWLEDGE
          AND AGREE THAT NS AND ITS RESPECTIVE FUNDERS, DIRECTORS, OFFICERS,
          VOLUNTEERS, EMPLOYEES, AFFILIATES, AGENTS, REPRESENTATIVES, LICENSORS,
          SUPPLIERS AND SERVICE PROVIDERS (COLLECTIVELY, THE “
          <span className={`underline`}>AFFILIATED ENTITIES</span>”):
          <ol className={styles.legalList}>
            <li>
              ASSUME NO RESPONSIBILITY WITH RESPECT TO THE USE OF DONATIONS BY
              PARTNER NONPROFITS, THE ACCURACY OR RELIABILITY OF ANY INFORMATION
              PROVIDED THROUGH THE SITE, OR DONORS’ INTERACTIONS WITH PARTNER
              NONPROFITS,
            </li>
            <li>
              DO NOT VERIFY THE IDENTITY OR CIRCUMSTANCES OF RECIPIENTS AND DO
              NOT SUPERVISE, DIRECT OR CONTROL PARTNER NONPROFITS OR PARTNER
              NONPROFITS’ USE OF DONATIONS, AND
            </li>
            <li>
              EXPRESSLY DISCLAIM ANY RESPONSIBILITY AND LIABILITY FOR THE
              CONDUCT, ACT OR OMISSIONS, OF ANY PARTNER NONPROFIT IN ANY MANNER,
              INCLUDING BUT NOT LIMITED TO ANY WARRANTY OR COMPLIANCE WITH ANY
              LAW, REGULATION OR CODE, AND ANY LIABILITY THAT MAY ARISE BETWEEN
              DONORS AND PARTNER NONPROFITS.
            </li>
          </ol>
        </p>
        <p className={`legalese-body-copy ${styles.legalParagraph}`}>
          The NS disclaimers in this section are in addition to the disclaimers
          made by NS elsewhere in this Agreement.
        </p>
      </section>

      <section className={styles.legalSection}>
        <h3>4. Changes.</h3>
        <p className={`legalese-body-copy ${styles.legalParagraph}`}>
          We may change this Agreement from time to time by notifying you of
          such changes by any reasonable means, including by posting a revised
          Agreement through the Site. Any such changes will not apply to any
          dispute between you and us arising prior to the date on which we
          posted the revised Agreement incorporating such changes, or otherwise
          notified you of such changes. Your use of the Site following any
          changes to this Agreement will constitute your acceptance of such
          changes. The “Last Updated” legend above indicates when this Agreement
          was last changed. We may, at any time and without liability, modify or
          discontinue all or part of the Site (including access to the Site via
          any third-party links); charge, modify or waive any fees required to
          use the Site; or offer opportunities to some or all Site users.
        </p>
      </section>

      <section className={styles.legalSection}>
        <h3>5. Information Submitted Through the Site.</h3>
        <p className={`legalese-body-copy ${styles.legalParagraph}`}>
          Your submission of information through the Site is governed by NS’s
          Privacy Policy, located at{" "}
          <a href="/privacy-policy">https://nbshare.org/privacy-policy/</a> (the
          “<span className={`underline`}>Privacy Policy</span>”). You represent
          and warrant that any information you provide in connection with the
          Site is and will remain accurate, and that you will maintain and
          update such information as needed.
        </p>
      </section>

      <section className={styles.legalSection}>
        <h3>6. Jurisdictional Issues.</h3>
        <p className={`legalese-body-copy ${styles.legalParagraph}`}>
          The Site is controlled and operated from the United States, is
          intended for use only by residents of the United States and is not
          intended to subject NS to any non-U.S. jurisdiction or law. The Site
          may not be appropriate or available for use in some non-U.S.
          jurisdictions. Any use of the Site is at your own risk, and you must
          comply with all applicable laws, rules and regulations in doing so. We
          may limit the Site’s availability at any time, in whole or in part, to
          any person, geographic area or jurisdiction that we choose.
        </p>
      </section>

      <section className={styles.legalSection}>
        <h3>7. Rules of Conduct.</h3>
        <p className={`legalese-body-copy ${styles.legalParagraph}`}>
          In connection with the Site, you use the Site only for lawful purposes
          and must not:
          <ul>
            <li>
              Post, transmit or otherwise make available through or in
              connection with the Site any virus, worm, Trojan horse, Easter
              egg, time bomb, spyware or other computer code, file or program
              that is or is potentially harmful or invasive or intended to
              damage or hijack the operation of, or to monitor the use of, any
              hardware, software or equipment (each, a “
              <span className={`underline`}>Virus</span>”).
            </li>
            <li>
              Transmit or otherwise make available through or in connection with
              the Site any materials that are or may be: (a) threatening,
              harassing, degrading, hateful or intimidating, or otherwise fail
              to respect the rights and dignity of others; (b) defamatory,
              libelous, fraudulent or otherwise tortious; (c) obscene, indecent,
              pornographic or otherwise objectionable; or (d) protected by
              copyright, trademark, trade secret, right of publicity or privacy
              or any other proprietary right, without the express prior written
              consent of the applicable owner.
            </li>
            <li>
              Use the Site for any commercial purpose, or for any purpose that
              is fraudulent or otherwise tortious or unlawful.
            </li>
            <li>Harvest or collect information about users of the Site.</li>
            <li>
              Interfere with or disrupt the operation of the Site or the servers
              or networks used to make the Site available, including by hacking
              or defacing any portion of the Site; or violate any requirement,
              procedure or policy of such servers or networks.
            </li>
            <li>Restrict or inhibit any other person from using the Site.</li>
            <li>
              Reproduce, modify, adapt, translate, create derivative works of,
              sell, rent, lease, loan, timeshare, distribute or otherwise
              exploit any portion of (or any use of) the Site except as
              expressly authorized herein, without NS’s express prior written
              consent.
            </li>
            <li>
              Reverse engineer, decompile or disassemble any portion of the
              Site, except where such restriction is expressly prohibited by
              applicable law.
            </li>
            <li>
              Remove any copyright, trademark or other proprietary rights notice
              from the Site.
            </li>
            <li>
              Frame or mirror any portion of the Site, or otherwise incorporate
              any portion of the Site into any product or service, without NS’s
              express prior written consent.
            </li>
            <li>Systematically download and store Site content.</li>
            <li>
              Use any robot, spider, site search/retrieval application or other
              manual or automatic device to retrieve, index, “scrape,” “data
              mine” or otherwise gather Site content, or reproduce or circumvent
              the navigational structure or presentation of the Site, without
              NS’s express prior written consent. Notwithstanding the foregoing,
              and subject to compliance with any instructions posted in the
              robots.txt file located in the Site’s root directory, NS grants to
              the operators of public search engines permission to use spiders
              to copy materials from the Site for the sole purpose of (and
              solely to the extent necessary for) creating publicly available,
              searchable indices of such materials, but not caches or archives
              of such materials. NS reserves the right to revoke such permission
              either generally or in specific cases, at any time and without
              notice.
            </li>
          </ul>
          You are responsible for obtaining, maintaining and paying for all
          hardware and all telecommunications and other services needed to use
          the Site.
        </p>
      </section>

      <section className={styles.legalSection}>
        <h3>8. Submissions.</h3>
        <p className={`legalese-body-copy ${styles.legalParagraph}`}>
          Site visitors may make available certain materials, including but not
          limited to information, comments, testimonials, stories, names, and
          photos, (each, a “<span className={`underline`}>Submission</span>”)
          through or in connection with the Site, including on the Site’s
          interactive services, such as commenting functionality. NS has no
          control over and is not responsible for any use or misuse (including
          any distribution) by any third party of Submissions. IF YOU CHOOSE TO
          MAKE ANY OF YOUR PERSONALLY IDENTIFIABLE OR OTHER INFORMATION PUBLICLY
          AVAILABLE THROUGH THE SITE, YOU DO SO AT YOUR OWN RISK.
        </p>
        <p className={`legalese-body-copy ${styles.legalParagraph}`}>
          We do not endorse the opinions or recommendations posted or sent by
          users shared through the Site and we disclaim all responsibility and
          liability in connection with all such information.
        </p>
      </section>

      <section className={styles.legalSection}>
        <h3>9. License.</h3>
        <p className={`legalese-body-copy ${styles.legalParagraph}`}>
          For purposes of clarity, you retain ownership of your Submissions. For
          each Submission, you hereby grant to us a worldwide, royalty-free,
          fully paid-up, non-exclusive, perpetual, irrevocable, transferable and
          fully sublicensable (through multiple tiers) license, without
          additional consideration to you or any third party, to reproduce,
          distribute, perform and display (publicly or otherwise), create
          derivative works of, adapt, modify, and otherwise share and use such
          Submission, in any format or media now known or hereafter developed,
          and for any purpose (including promotional purposes, such as
          testimonials).
        </p>
        <p className={`legalese-body-copy ${styles.legalParagraph}`}>
          In addition, if you provide to us any ideas, proposals, suggestions or
          other materials including but not limited to suggestions for
          improvements or modifications to our services, or ideas for new
          services (collectively, “<span className={`underline`}>Feedback</span>
          ”), whether related to the Site or otherwise, such Feedback will be
          deemed a Submission, and you hereby acknowledge and agree that such
          Feedback is not confidential, and that your provision of such Feedback
          is gratuitous, unsolicited and without restriction, and does not place
          NS under any fiduciary or other obligation.
        </p>
        <p className={`legalese-body-copy ${styles.legalParagraph}`}>
          You represent and warrant that you have all rights necessary to grant
          the licenses granted in this section, and that your Submissions, and
          your provision thereof through and in connection with the Site are
          complete and accurate, and not fraudulent, tortious or otherwise in
          violation of any applicable law or any right of any third party. You
          further irrevocably waive any “moral rights” or other rights with
          respect to attribution of authorship or integrity of materials
          regarding each Submission that you may have under any applicable law
          under any legal theory.
        </p>
      </section>

      <section className={styles.legalSection}>
        <h3>10. Monitoring.</h3>
        <p className={`legalese-body-copy ${styles.legalParagraph}`}>
          We may (but have no obligation to) monitor, evaluate, alter or remove
          Submissions before or after they appear on the Site, or analyze your
          access to or use of the Site. We may disclose information regarding
          your access to and use of the Site, and the circumstances surrounding
          such access and use, to anyone for any reason or purpose.
        </p>
      </section>

      <section className={styles.legalSection}>
        <h3>11. Your Limited Rights.</h3>
        <p className={`legalese-body-copy ${styles.legalParagraph}`}>
          Subject to your compliance with this Agreement, and solely for so long
          as you are permitted by NS to use the Site, you may view one (1) copy
          of any portion of the Site to which we provide you access under this
          Agreement, on any single device, solely for your personal,
          non-commercial use.
        </p>
      </section>

      <section className={styles.legalSection}>
        <h3>12. NS’s Proprietary Rights.</h3>
        <p className={`legalese-body-copy ${styles.legalParagraph}`}>
          We and our suppliers own the Site, which is protected by proprietary
          rights and laws. Our trade names, trademarks and service marks include
          NEIGHBORSHARE and any associated logos. All trade names, trademarks,
          service marks and logos on the Site not owned by us are the property
          of their respective owners, including Partner Nonprofits. You may not
          use our trade names, trademarks, service marks or logos in connection
          with any product or service that is not ours, or in any manner that is
          likely to cause confusion. Nothing contained on the Site should be
          construed as granting any right to use any trade names, trademarks,
          service marks or logos without the express prior written consent of
          the owner.
        </p>
      </section>

      <section className={styles.legalSection}>
        <h3>13. Third Party Materials; Links.</h3>
        <p className={`legalese-body-copy ${styles.legalParagraph}`}>
          Certain Site functionality may make available access to information,
          products, services and other materials made available by third
          parties, such as Partner Nonprofits (“
          <span className={`underline`}>Third Party Materials</span>”), or allow
          for the routing or transmission of such Third Party Materials,
          including via links. When you visit a linked site, you should read the
          terms of use and privacy policy that govern that particular linked
          site. By using such functionality, you are directing us to access,
          route and transmit to you the applicable Third Party Materials.
        </p>
        <p className={`legalese-body-copy ${styles.legalParagraph}`}>
          We neither control nor endorse, nor are we responsible for, any Third
          Party Materials, including the accuracy, validity, timeliness,
          completeness, reliability, integrity, quality, legality, usefulness or
          safety of Third Party Materials, or any intellectual property rights
          therein. Certain Third Party Materials may, among other things, be
          inaccurate, misleading or deceptive. Nothing in this Agreement shall
          be deemed to be a representation or warranty by NS with respect to any
          Third Party Materials. We have no obligation to monitor Third Party
          Materials, and we may block or disable access to any Third Party
          Materials (in whole or part) through the Site at any time. In
          addition, the availability of any Third Party Materials through the
          Site does not imply our endorsement of, or our affiliation with, any
          provider of such Third Party Materials, nor does such availability
          create any legal relationship between you and any such provider.
        </p>
        <p className={`legalese-body-copy ${styles.legalParagraph}`}>
          YOUR USE OF THIRD PARTY MATERIALS IS AT YOUR OWN RISK AND IS SUBJECT
          TO ANY ADDITIONAL TERMS, CONDITIONS AND POLICIES APPLICABLE TO SUCH
          THIRD PARTY MATERIALS (SUCH AS TERMS OF SERVICE OR PRIVACY POLICIES OF
          THE PROVIDERS OF SUCH THIRD PARTY MATERIALS).
        </p>
      </section>

      <section className={styles.legalSection}>
        <h3>14. DISCLAIMER OF WARRANTIES.</h3>
        <p className={`legalese-body-copy ${styles.legalParagraph}`}>
          TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW: (A) THE SITE AND
          THIRD PARTY MATERIALS ARE MADE AVAILABLE TO YOU ON AN “AS IS,” “WHERE
          IS” AND “WHERE AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND,
          WHETHER EXPRESS, IMPLIED OR STATUTORY, AND (B) NS DISCLAIMS ALL
          WARRANTIES WITH RESPECT TO THE SITE AND THIRD PARTY MATERIALS,
          INCLUDING THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
          PURPOSE, NON-INFRINGEMENT AND TITLE. ALL DISCLAIMERS OF ANY KIND
          (INCLUDING IN THIS SECTION AND ELSEWHERE IN THIS AGREEMENT) ARE MADE
          FOR THE BENEFIT OF BOTH NS AND ITS AFFILIATES AND THE AFFILIATED
          ENTITIES AND THEIR RESPECTIVE SUCCESSORS AND ASSIGNS.
        </p>
        <p className={`legalese-body-copy ${styles.legalParagraph}`}>
          While we try to maintain the timeliness, integrity and security of the
          Site, we do not guarantee that the Site is or will remain updated,
          complete, correct or secure, or that access to the Site will be
          uninterrupted. The Site may include inaccuracies, errors and materials
          that violate or conflict with this Agreement. Additionally, third
          parties may make unauthorized alterations to the Site. If you become
          aware of any such alteration, contact us at{" "}
          <a href="mailto:help@nbshare.org">help@nbshare.org</a> with a
          description of such alteration and its location on the Site.
        </p>
      </section>
      <section className={styles.legalSection}>
        <h3>15. LIMITATION OF LIABILITY.</h3>
        <p className={`legalese-body-copy ${styles.legalParagraph}`}>
          TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW: (A) IN NO EVENT
          WILL NS OR ITS AFFILIATED ENTITIES, AND THEIR RESPECTIVE SUCCESSORS
          AND ASSIGNS, BE LIABLE FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL,
          SPECIAL, EXEMPLARY, PUNITIVE, DIRECT OR ANY OTHER DAMAGES OF ANY KIND,
          UNDER ANY CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY OR
          OTHER THEORY, INCLUDING DAMAGES FOR LOSS OF PROFITS, USE OR DATA, LOSS
          OF OTHER INTANGIBLES, LOSS OF SECURITY OF TRANSMISSION (INCLUDING
          UNAUTHORIZED INTERCEPTION BY THIRD PARTIES OF ANY SUBMISSIONS), EVEN
          IF ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH DAMAGES OR LOSSES;
          (B) WITHOUT LIMITING THE FOREGOING, NS OR ITS AFFILIATED ENTITIES, AND
          THEIR RESPECTIVE SUCCESSORS AND ASSIGNS, WILL NOT BE LIABLE FOR
          DAMAGES OF ANY KIND RESULTING FROM YOUR USE OF OR INABILITY TO USE THE
          SITE, ANY DONATION OR ANY THIRD PARTY MATERIALS, INCLUDING FROM ANY
          VIRUS THAT MAY BE TRANSMITTED IN CONNECTION THEREWITH; (C) YOUR SOLE
          AND EXCLUSIVE REMEDY FOR DISSATISFACTION WITH THE SITE OR ANY THIRD
          PARTY MATERIALS IS TO STOP USING THE SITE; AND (D) THE MAXIMUM
          AGGREGATE LIABILITY OF NS AND THE AFFILIATED ENTITIES FOR ALL DAMAGES,
          LOSSES AND CAUSES OF ACTION, WHETHER IN CONTRACT, TORT (INCLUDING
          NEGLIGENCE) OR OTHERWISE, SHALL BE $10.00. ALL LIMITATIONS OF
          LIABILITY OF ANY KIND (INCLUDING IN THIS SECTION AND ELSEWHERE IN THIS
          AGREEMENT) ARE MADE ON BEHALF OF BOTH NS AND THE AFFILIATED ENTITIES,
          AND THEIR RESPECTIVE SUCCESSORS AND ASSIGNS.
        </p>
        <p className={`legalese-body-copy ${styles.legalParagraph}`}>
          Applicable law may not allow for limitations on certain implied
          warranties, or exclusions or limitations of certain damages; solely to
          the extent that such law applies to you, some or all of the above
          disclaimers, exclusions or limitations may not apply to you, and you
          may have certain additional rights.
        </p>
      </section>

      <section className={styles.legalSection}>
        <h3>16. Indemnity.</h3>
        <p className={`legalese-body-copy ${styles.legalParagraph}`}>
          To the fullest extent permitted under applicable law, you agree to
          defend, indemnify and hold harmless NS and the Affiliated Entities,
          and their respective successors and assigns, from and against all
          claims, liabilities, damages, judgments, awards, losses, costs,
          expenses and fees (including attorneys’ fees) arising out of or
          relating to (a) your use of, or activities in connection with, the
          Site (including Donations); and (b) any violation or alleged violation
          of this Agreement by you.
        </p>
      </section>

      <section className={styles.legalSection}>
        <h3>17. Termination.</h3>
        <p className={`legalese-body-copy ${styles.legalParagraph}`}>
          This Agreement is effective until terminated. NS may terminate or
          suspend your use of the Site at any time and without prior notice, for
          any or no reason, including if NS believes that you have violated or
          acted inconsistently with the letter or spirit of this Agreement. Upon
          any such termination or suspension, your right to use the Site will
          immediately cease, and NS may, without liability to you or any third
          party, immediately deactivate or delete your user name, password and
          account, and all associated materials, without any obligation to
          provide any further access to such materials. Sections 1-3, 5-10 and
          12–22 shall survive any expiration or termination of this Agreement.
        </p>
      </section>

      <section className={styles.legalSection}>
        <h3>18. Governing Law; Arbitration.</h3>
        <p className={`legalese-body-copy ${styles.legalParagraph}`}>
          This Agreement is governed by and shall be construed in accordance
          with the laws of the United States (including federal arbitration law)
          and the State of Delaware without regard to its principles of
          conflicts of law, and regardless of your location.
        </p>
        <p className={`legalese-body-copy ${styles.legalParagraph}`}>
          EXCEPT FOR DISPUTES THAT QUALIFY FOR SMALL CLAIMS COURT, ALL DISPUTES
          ARISING OUT OF OR RELATED TO THIS AGREEMENT OR ANY ASPECT OF THE
          RELATIONSHIP BETWEEN YOU AND NS, WHETHER BASED IN CONTRACT, TORT,
          STATUTE, FRAUD, MISREPRESENTATION OR ANY OTHER LEGAL THEORY, WILL BE
          RESOLVED THROUGH FINAL AND BINDING ARBITRATION BEFORE A NEUTRAL
          ARBITRATOR INSTEAD OF IN A COURT BY A JUDGE OR JURY AND YOU AGREE THAT
          NS AND YOU ARE EACH WAIVING THE RIGHT TO TRIAL BY A JURY. SUCH
          DISPUTES INCLUDE, WITHOUT LIMITATION, DISPUTES ARISING OUT OF OR
          RELATING TO INTERPRETATION OR APPLICATION OF THIS ARBITRATION
          PROVISION, INCLUDING THE ENFORCEABILITY, REVOCABILITY OR VALIDITY OF
          THE ARBITRATION PROVISION OR ANY PORTION OF THE ARBITRATION PROVISION.
          ALL SUCH MATTERS SHALL BE DECIDED BY AN ARBITRATOR AND NOT BY A COURT
          OR JUDGE.
        </p>
        <p className={`legalese-body-copy ${styles.legalParagraph}`}>
          YOU AGREE THAT ANY ARBITRATION UNDER THIS AGREEMENT WILL TAKE PLACE ON
          AN INDIVIDUAL BASIS; CLASS ARBITRATIONS AND CLASS ACTIONS ARE NOT
          PERMITTED AND YOU ARE AGREEING TO GIVE UP THE ABILITY TO PARTICIPATE
          IN A CLASS ACTION. The arbitration will be administered by the
          American Arbitration Association under its Consumer Arbitration Rules,
          as amended by this Agreement. The Consumer Arbitration Rules are
          available online at{" "}
          <a href="https://www.adr.org/sites/default/files/Consumer%20Rules.pdf">
            https://www.adr.org/sites/default/files/Consumer%20Rules.pdf
          </a>{" "}
          . The arbitrator will conduct hearings, if any, by teleconference or
          videoconference, rather than by personal appearances, unless the
          arbitrator determines upon request by you or by us that an in-person
          hearing is appropriate. Any in-person appearances will be held at a
          location which is reasonably convenient to both parties with due
          consideration of their ability to travel and other pertinent
          circumstances. If the parties are unable to agree on a location, such
          determination should be made by the AAA or by the arbitrator. The
          arbitrator’s decision will follow the terms of this Agreement and will
          be final and binding. The arbitrator will have authority to award
          temporary, interim or permanent injunctive relief or relief providing
          for specific performance of this Agreement, but only to the extent
          necessary to provide relief warranted by the individual claim before
          the arbitrator. The award rendered by the arbitrator may be confirmed
          and enforced in any court having jurisdiction thereof. Notwithstanding
          any of the foregoing, nothing in this Agreement will preclude you from
          bringing issues to the attention of federal, state or local agencies
          and, if the law allows, they can seek relief against us for you.
        </p>
      </section>

      <section className={styles.legalSection}>
        <h3>19. Filtering.</h3>
        <p className={`legalese-body-copy ${styles.legalParagraph}`}>
          We hereby notify you that parental control protections (such as
          computer hardware, software or filtering services) are commercially
          available that may assist you in limiting access to material that is
          harmful to minors. Information identifying current providers of such
          protections is available from
          https://en.wikipedia.org/wiki/Comparison_of_content-control_software_and_providers.
          Please note that NS does not endorse any of the products or services
          listed on such site.
        </p>
      </section>

      <section className={styles.legalSection}>
        <h3>20. Information or Complaints.</h3>
        <p className={`legalese-body-copy ${styles.legalParagraph}`}>
          If you have a question or complaint regarding the Site, please send an
          e-mail to help@nbshare.org. Please note that e-mail communications
          will not necessarily be secure; accordingly you should not include
          credit card information or other sensitive information in your e-mail
          correspondence with us. California residents may reach the Complaint
          Assistance Unit of the Division of Consumer Services of the California
          Department of Consumer Affairs by mail at 1625 North Market Blvd.,
          Sacramento, CA 95834, or by telephone at (916) 445-1254 or (800)
          952-5210.
        </p>
      </section>

      <section className={styles.legalSection}>
        <h3>21. Copyright Infringement Claims.</h3>
        <p className={`legalese-body-copy ${styles.legalParagraph}`}>
          The Digital Millennium Copyright Act of 1998 (the “DMCA”) provides
          recourse for copyright owners who believe that material appearing on
          the Internet infringes their rights under U.S. copyright law. If you
          believe in good faith that materials available on the Site infringe
          your copyright, you (or your agent) may send to NS a written notice by
          mail, e-mail or fax, requesting that NS remove such material or block
          access to it. If you believe in good faith that someone has wrongly
          filed a notice of copyright infringement against you, the DMCA permits
          you to send to NS a counter-notice. Notices and counter-notices must
          meet the then-current statutory requirements imposed by the DMCA. See{" "}
          <a href="http://www.copyright.gov/">http://www.copyright.gov/</a> for
          details.
        </p>
      </section>

      <section className={styles.legalSection}>
        <h3>22. Miscellaneous.</h3>
        <p className={`legalese-body-copy ${styles.legalParagraph}`}>
          This Agreement does not, and shall not be construed to, create any
          partnership, joint venture, employer-employee, agency or
          franchisor-franchisee relationship between you and NS. If any
          provision of this Agreement is found to be unlawful, void or for any
          reason unenforceable, that provision will be deemed severable from
          this Agreement and will not affect the validity and enforceability of
          any remaining provision. You may not assign, transfer or sublicense
          any or all of your rights or obligations under this Agreement without
          our express prior written consent. We may assign, transfer or
          sublicense any or all of our rights or obligations under this
          Agreement without restriction. This Agreement will be binding upon and
          will inure to the benefit of the parties, their successors and
          permitted assigns. No waiver by either party of any breach or default
          under this Agreement will be deemed to be a waiver of any preceding or
          subsequent breach or default. Any heading, caption or section title
          contained herein is for convenience only, and in no way defines or
          explains any section or provision. All terms defined in the singular
          shall have the same meanings when used in the plural, where
          appropriate and unless otherwise specified. Any use of the term
          “including” or variations thereof in this Agreement shall be construed
          as if followed by the phrase “without limitation.” This Agreement,
          including any terms and conditions incorporated herein, is the entire
          agreement between you and NS relating to the subject matter hereof,
          and supersedes any and all prior or contemporaneous written or oral
          agreements or understandings between you and NS relating to such
          subject matter. Notices to you (including notices of changes to this
          Agreement) may be made via posting to the Site or by e-mail (including
          in each case via links), or by regular mail. Without limitation, a
          printed version of this Agreement and of any notice given in
          electronic form shall be admissible in judicial or administrative
          proceedings based upon or relating to this Agreement to the same
          extent and subject to the same conditions as other business documents
          and records originally generated and maintained in printed form. NS
          will not be responsible for any failure to fulfill any obligation due
          to any cause beyond its control.
        </p>
      </section>
      <p className={`legalese-body-copy ${styles.legalParagraph}`}>
        Site &copy; 2020 NeighborShare, Inc. unless otherwise noted. All rights
        reserved.
      </p>
    </div>
  </>
)

export default TermsOfService
